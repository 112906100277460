import { v4 as uuidv4 } from "uuid";
import {
  getUpdatedSingleCustomer,
  setImageLoading,
  setImageQuestionId,
} from "../redux/customer";
import { UploadQueue } from "./imageUploadClass";
import { handleImageCompression } from "../helper/helper";
import { uploadImage } from "../services/customers.service";

const uploadQueue = new UploadQueue();

export const fileChangeHandler = async (
  event,
  currentImages,
  queObj,
  dispatch,
  findQueAndSetAnswer,
  findQueAndSetBlobAnswer
) => {
  const selectedFile = event.target.files[0];
  if (selectedFile) {
    dispatch(setImageQuestionId(queObj?.id));

    // Create a blob URL for the selected image
    const blobURL = URL.createObjectURL(selectedFile);

    // let tempImageId = uuidv4();
    let updatedImagesArray = [...(currentImages || []), blobURL];

    findQueAndSetBlobAnswer(updatedImagesArray, queObj);

    // Add the upload task to the queue
    uploadQueue.addToQueue(() =>
      uploadImageFile(
        selectedFile,
        blobURL,
        findQueAndSetAnswer,
        dispatch,
        queObj
      )
    );
  }
};

const uploadImageFile = async (
  selectedFile,
  blobURL,
  findQueAndSetAnswer,
  dispatch,
  queObj
) => {
  const compressedFile = await handleImageCompression(selectedFile);
  let formData = new FormData();
  formData.append("file", compressedFile);
  formData.append("originalname", selectedFile?.name);

  try {
    let imageUploaded = await uploadImage(formData);

    if (imageUploaded.data.status) {
      findQueAndSetAnswer("true", blobURL, queObj, imageUploaded.data.entity);
    } else {
      findQueAndSetAnswer("false", blobURL, queObj);
    }
  } catch (error) {
    findQueAndSetAnswer("false", blobURL);
  } finally {
    dispatch(setImageLoading(false));
  }
};
