export class UploadQueue {
  constructor() {
    this.queue = [];
    this.processing = false;
  }

  addToQueue(uploadFunction) {
    this.queue.push(uploadFunction);
    this.processQueue();
  }

  async processQueue() {
    if (this.processing) return;
    this.processing = true;
    while (this.queue.length > 0) {
      const uploadFunction = this.queue.shift();
      await uploadFunction();
    }
    this.processing = false;
  }
}
